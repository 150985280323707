/**
 * main.js
 * @desc loads all dependencies dynamically via querySelectors
 */
import Capitan from '../js/Capitan/Capitan';
import 'regenerator-runtime/runtime'; // Necessary for storybook to show components

var storybook = document.querySelector('.storybook');
/**
 * check for browser capabilities and load polyfills if needed
 * @param callback - run callback after successful import
 * todo: modernizr integration
 */

function loadPolyfills(callback) {} // check if polyfills are needed
// create and append polyfill script that is generated by webpack

/**
 * load all needed dependencies for the components
 * @param callback - run callback after successful import
 */


function loadDependencies(callback) {
  // import handlers and bundle them with webpack
  callback();
}
/**
 * initialize components
 */


function loadComponents() {
  // import the component with webpack dynamic import
  // @see: https://webpack.js.org/api/module-methods/#import-
  import(
  /* webpackPrefetch: true */
  'lazysizes');
  import(
  /* webpackChunkName: "navigation" */
  '../../components/navigation/main').then(function (component) {
    component.init();
  });
  import(
  /* webpackChunkName: "scrollable" */
  '../../components/scrollable/scrollable').then(function (component) {
    component.init();
  });
}

if (Capitan.Vars.doc.querySelector('.scrolltop') || storybook) {
  import(
  /* webpackChunkName: "scrolltop" */
  '../../components/scrolltop/scrolltop').then(function (component) {
    component.init();
  });
}

if (document.querySelector('.stage')) {
  document.querySelector('body').classList.add('has-stage');
}

if (document.querySelector('.stage') && document.querySelector('.breadcrumb') || document.querySelector('.stage--guide') && document.querySelector('.breadcrumb')) {
  document.querySelector('body').classList.add('has-stage');
  import(
  /* webpackChunkName: "stage" */
  '../../components/stage/stage').then(function (component) {
    component.init();
  });
}

if (document.querySelector('.header') || storybook) {
  import(
  /* webpackChunkName: "header" */
  '../../components/header/header').then(function (component) {
    component.init();
  });
}

if (document.querySelector('.form') || storybook) {
  import(
  /* webpackChunkName: "form-validation" */
  '../../components/form/form-validation').then(function (component) {
    component.init();
  });
}

if (document.querySelector('.has-iframe') || storybook) {
  import(
  /* webpackChunkName: "form-validation" */
  '../../components/embed-iframe/embed-iframe').then(function (component) {
    component.init();
  });
}

if (document.querySelector('.form') || storybook) {
  import(
  /* webpackChunkName: "form" */
  '../../components/form/form').then(function (component) {
    component.init();
  });
}

if (document.querySelector('.tile__content--collapsable') || storybook) {
  import(
  /* webpackChunkName: "tile__content--collapsable" */
  '../../components/tile/tile').then(function (component) {
    component.init();
  });
}

if (document.querySelector('.reveal') || storybook) {
  import(
  /* webpackChunkName: "reveal" */
  '../../components/reveal/reveal').then(function (component) {
    component.init();
  });
}

if (Capitan.Vars.doc.querySelector('.tooltip') || storybook) {
  import(
  /* webpackChunkName: "tooltip" */
  '../../components/tooltip/tooltip').then(function (component) {
    component.init();
  });
}

if (document.querySelector('.notification') || storybook) {
  import(
  /* webpackChunkName: "notification" */
  '../../components/notification/notification').then(function (component) {
    component.init();
  });
}

if (document.querySelector('.modal') || storybook) {
  import(
  /* webpackChunkName: "modal" */
  '../../components/modal/modal').then(function (component) {
    component.init();
  });
}

if (document.querySelector('.custom-select') || storybook) {
  import(
  /* webpackChunkName: "custom-select" */
  '../../components/custom-select/custom-select').then(function (component) {
    component.init();
  });
}

if (document.querySelector('.customer-bar--autoplay') || storybook) {
  import(
  /* webpackChunkName: "customer-bar-autoplay" */
  '../../components/customer-bar/customer-bar-autoplay').then(function (component) {
    component.init();
  });
}

if (document.querySelector('.customer-module') || storybook) {
  import(
  /* webpackChunkName: "customer-module" */
  '../../components/customer-module/customer-module').then(function (component) {
    component.init();
  });
}

if (Capitan.Vars.doc.querySelector('.swiper') || storybook) {
  import(
  /* webpackChunkName: "swiper" */
  '../../components/swiper/swiper').then(function (component) {
    component.init();
  });
}

if (Capitan.Vars.doc.querySelector('.swiper-wrapper') || storybook) {
  import(
  /* webpackChunkName: "swiper" */
  '../../components/image/image').then(function (component) {
    component.init();
  });
}

if (Capitan.Vars.doc.querySelector('.swiper-thumbs') || storybook) {
  import(
  /* webpackChunkName: "swiper-thumbs" */
  '../../components/swiper/swiper-thumbs').then(function (component) {
    component.init();
  });
}

function loadServiceWorker() {
  // Check that service workers are supported (e.g. only https)
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/sw.js');
  }
}

if (document.querySelector('.embed-video') || storybook) {
  import(
  /* webpackChunkName: "embed-video" */
  '../../components/embed-video/embed-video').then(function (component) {
    component.init();
  });
}

export default function init() {
  //Use Service Worker when needed
  //loadServiceWorker();
  //todo: implementation of detection rules
  if (Capitan.Vars.isIE || Capitan.Vars.isEDGE) {
    loadPolyfills();
  }

  loadDependencies(function () {
    loadComponents();
  });
}
init();